if (typeof Object.assign !== "function") {
  Object.assign = function (target) {
    var args = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      args[_i - 1] = arguments[_i];
    }

    if (!target) {
      throw TypeError("Cannot convert undefined or null to object");
    }

    var _loop_1 = function (source) {
      if (source) {
        Object.keys(source).forEach(function (key) {
          return target[key] = source[key];
        });
      }
    };

    for (var _a = 0, args_1 = args; _a < args_1.length; _a++) {
      var source = args_1[_a];

      _loop_1(source);
    }

    return target;
  };
}

export default {};